import {createRouter, createWebHistory} from 'vue-router';

const router = createRouter({
    history: createWebHistory(),
    routes: [
        //文章详情 带评论的
        {
            path: '/explain',
            component: () => import('@/view/index.vue')
        },
        //文章详情 带评论的
        {
            path: '/policy',
            component: () => import('@/view/policy.vue')
        },
        //文章详情 带评论的
        {
            path: '/introduce',
            component: () => import('@/view/introduce.vue')
        },
    ],
});

export default router;
